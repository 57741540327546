import { useEffect, useState } from 'react';

const useShowError = (errorList: string[]): boolean => {
  const [isErrorShown, setIsErrorShown] = useState(false);

  useEffect(() => {
    setIsErrorShown(Boolean(errorList?.length));
  }, [errorList]);

  return isErrorShown;
};

export default useShowError;
